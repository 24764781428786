import cleaningIcon from '../components/assets/cleaning-dark.svg';
import cleaningLightIcon from '../components/assets/cleaning.png';
import maintenanceIcon from '../components/assets/maintenance-dark.svg';
import maintenanceLightIcon from '../components/assets/maintenance.png';
import taskIcon from '../components/assets/file.svg';
import taskLightIcon from '../components/assets/file-light.png';
import improvementIcon from '../components/assets/settings.svg';
import improvementLightIcon from '../components/assets/settings-light.png';
import contactIcon from '../components/assets/phone.svg';
import contactLightIcon from '../components/assets/phone-light.png';
import airbnbImage from '../components/assets/airbnb.svg';
import ownerBlockImage from '../components/assets/owner-block.svg';
import manualBlockImage from '../components/assets/manual-block.svg';
import bookingImage from '../components/assets/booking.svg';
import directBookingImage from '../components/assets/direct.svg';
import vrboImage from '../components/assets/vrbo.svg';
import { ChannelOption } from '../api/type';

export const getJobIcon = (type: string) => {
  switch (type) {
    case 'Cleaning':
      return cleaningIcon;
    case 'Maintenance':
      return maintenanceIcon;
    case 'Task':
      return taskIcon;
    case 'Improvement':
      return improvementIcon;
    case 'Contact':
      return contactIcon;
    default:
      return improvementIcon;
  }
};

export const getJobLightIcon = (type: string) => {
  switch (type) {
    case 'Cleaning':
      return cleaningLightIcon;
    case 'Maintenance':
      return maintenanceLightIcon;
    case 'Task':
      return taskLightIcon;
    case 'Improvement':
      return improvementLightIcon;
    case 'Contact':
      return contactLightIcon;
    default:
      return improvementLightIcon;
  }
};

export const getImageForBookingChannel = (type: string) => {
  switch (type) {
    case 'Airbnb':
      return airbnbImage;
    case 'Owners Block':
      return ownerBlockImage;
    case 'Booking.com':
      return bookingImage;
    case 'booking.com':
      return bookingImage;
    case 'Direct':
      return directBookingImage;
    case 'Manual Block':
      return manualBlockImage;
    case 'VRBO':
      return vrboImage;
    default:
      return directBookingImage;
  }
};

export const formatChannel = (channel: string) => {
  switch (channel) {
    case 'airbnb':
      return 'Airbnb';
    case 'direct':
      return 'Direct';
    case 'booking':
      return 'Booking.com';
    case 'vrbo':
      return 'VRBO';
    case 'manual':
      return 'Manual Block';
    case 'owner':
      return 'Owners Block';
    default:
      return channel;
  }
};

export const channelOptions: { [key: string]: ChannelOption } = {
  airbnb: { label: 'Airbnb', icon: airbnbImage, value: 'airbnb' },
  booking: { label: 'Booking.com', icon: bookingImage, value: 'booking' },
  vrbo: { label: 'VRBO', icon: vrboImage, value: 'vrbo' },
  direct: { label: 'Direct', icon: directBookingImage, value: 'direct' },
  manual: { label: 'Manual Block', icon: manualBlockImage, value: 'manual' },
  owner: { label: 'Owners Block', icon: ownerBlockImage, value: 'owner' },
};