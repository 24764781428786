import React, { useState } from "react";
import { PropertyList, PropertySingleList } from "../../api/type";
import homeDark from '../assets/home-dark.svg';
import down from '../assets/down.svg';
import xIcon from '../assets/x-24.svg';
import pin from '../assets/pin.svg';
import SkeletonRow from "../dropdown/SkeletonRow";

interface PropertyDropdownProps {
  allProperties: PropertySingleList[];
  handlePropertySelect: (propertyId: string) => void;
  selectedProperty: string | null;
  isFetchingMore: boolean;
}

const PropertyModal: React.FC<PropertyDropdownProps> = ({
  allProperties,
  handlePropertySelect,
  selectedProperty,
  isFetchingMore
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempSelectedProperty, setTempSelectedProperty] = useState<string | null>(
    selectedProperty
  );

  const filteredProperties = allProperties.filter((property) =>
    property.propertyName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const openModal = () => {
    setTempSelectedProperty(selectedProperty);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setTempSelectedProperty(selectedProperty);
    setIsModalOpen(false);
  };

  const confirmSelection = () => {
    if (tempSelectedProperty) {
      handlePropertySelect(tempSelectedProperty);
    }
    setIsModalOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        className="date-filter-container ml-8"
        onClick={openModal}
        style={{
          backgroundColor: "#F8F6EE",
          alignItems: "center",
          padding: "10px",
          fontSize: "16px",
          fontWeight: 600,
          color: "#143836",
          borderRadius: "8px",
          cursor: "pointer",
          width: "350px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {(() => {
          const selectedProp = allProperties.find(
            (prop) => prop.id === selectedProperty
          );

          if (selectedProp) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {selectedProp.thumbnailImageUrl ? (
                  <img
                    src={selectedProp.thumbnailImageUrl}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      marginRight: "8px",
                    }}
                  />
                ) : (<div className={'bar-property-image-none'}>
                  <img src={homeDark} alt={selectedProp.propertyName || 'Property'} />
                </div>)}

                <span className="month-dropdown">
                  {selectedProp.propertyName
                    ? selectedProp.propertyName.length > 28
                      ? `${selectedProp.propertyName.substring(0, 28)}...`
                      : selectedProp.propertyName
                    : "Name Not Available"}
                </span>
              </div>
            );
          } else {
            return (<div style={{ display: "flex", alignItems: "center" }}><div className="spinner-message"></div> Select Property</div>);
          }
        })()}
        <img src={down} alt="down" />

      </div>

      {isModalOpen && (
        <>
          <div
            onClick={closeModal}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              zIndex: 999,
            }}
          />

          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#FFFEF8",
              borderRadius: "24px",
              zIndex: 1000,
              width: "576px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              display: "flex",
              flexDirection: "column",
              maxHeight: "80vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "24px 32px 16px 32px",
              }}
            >
              <div>
                <h3 style={{ fontWeight: 600, fontSize: "20px", color: "#14383b" }}>Select Property</h3>
              </div>

              <button
                onClick={closeModal}
                className="modal-exit"
              >
                <img src={xIcon} alt="x-icon" />
              </button>
            </div>
            <hr style={{ border: "1px solid #ECEEE5", margin: "0 0 0 0" }} />
            <div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search property"
                style={{
                  width: "84%",
                  margin: "16px 32px 16px 32px",
                  padding: "10px 14px 10px 14px",
                  borderRadius: "8px",
                  background: "#F8F6EE",
                  border: "1px solid #F8F6EE",
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
              />
            </div>

            <div style={{ overflowY: "auto", flexGrow: 1, marginBottom: "10px" }}>
              {filteredProperties.length > 0 ? (
                filteredProperties.map((property) => (
                  <>
                    <div
                      key={property.id}
                      className="modal-property-container"
                      style={{
                        backgroundColor:
                          tempSelectedProperty === property.id ? "#C6F2CA" : "",
                      }}
                      onClick={() => setTempSelectedProperty(property.id)}
                    >
                      <input
                        type="radio"
                        checked={tempSelectedProperty === property.id}
                        onChange={() => setTempSelectedProperty(property.id)}
                        style={{
                          accentColor: "#1C9B5E",
                          width: "20px",
                          height: "20px",
                          marginRight: "16px",
                          cursor: "pointer",
                        }}
                      />
                      <div className="property-container">
                        <div className={property?.thumbnailImageUrl ? 'modal-property-image' : 'modal-property-image-none'}>
                          <img src={property?.thumbnailImageUrl || homeDark} alt={property?.propertyName || 'Property'} />
                        </div>

                        <div>

                          <h3 style={{ fontSize: "16px", fontWeight: 600, color: "#14383b" }}>
                            {property.propertyName
                              ? property.propertyName.length > 40
                                ? `${property.propertyName.substring(0, 40)}...`
                                : property.propertyName
                              : "Name Not Available"}
                          </h3>
                          <div className="d-flex">
                            <img src={pin} alt="address-pin" />
                            <p style={{ fontSize: "14px", fontWeight: 400, color: "#3B4D4C", marginLeft: "8px" }}>{property?.address}</p></div>

                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (

                isFetchingMore ? (<div style={{ padding: "32px", color: "#B1B2AA", textAlign: "center" }} > Please wait, fetching properties...</div>) : (<div style={{ padding: "32px", color: "#B1B2AA", textAlign: "center" }} > No properties found</div>)

              )}
            </div>
            <hr style={{ border: "1px solid #ECEEE5", margin: "0 0 0 0" }} />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "16px 32px 24px 32px"
              }}
            >
              <button
                onClick={closeModal}
                className="secondary-btn mr-16"
              >
                Cancel
              </button>
              <button
                onClick={confirmSelection}
                className="primary-btn"
              >
                Select Property
              </button>
            </div>
          </div>
        </>
      )
      }
    </div >
  );
};

export default PropertyModal;
