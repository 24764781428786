import React, { useMemo, useState, useCallback, useEffect, useContext } from "react";
import { useQueryClient, useQueries, useQuery } from "react-query";
import moment from "moment";
import CustomMonthDropdown from "./dropdown/CustomMonthDropdown";
import leftIcon from "./assets/arrow-left.svg";
import rightIcon from "./assets/arrow-right.svg";
import calendarCheck from "./assets/calendar-check.svg";
import { fetchPropertiesReservations, fetchSingleViewProperties, fetchTotalProperties } from "../api/request";
import { ImportantOrgContext } from "../App";
import { CalendarPMS, Listing as ListingType, PropertyList, Job, PropertySingleList } from "../api/type";
import SingleViewCalendar from "./single_view/SingleViewCalendar";
import PropertyDropdown from "./modals/PropertyModal";

interface Day {
  date: string;
  dayOfWeek: number;
  day: number | null;
}

interface CalendarDataPage {
  calendarData: ListingType[];
  properties: PropertyList[];
  propertiesCount: number | undefined;
}

const generateMonthOptions = () => {
  const startMonth = moment("2024-01-01");
  const endMonth = moment("2025-12-31");
  const months = [];
  const current = startMonth.clone();

  while (current.isSameOrBefore(endMonth)) {
    months.push(current.format("MMMM YYYY"));
    current.add(1, "month");
  }

  return months;
};

const SingleViewCalendarApp: React.FC = () => {
  const { importantOrgId, userId, userType } = useContext(ImportantOrgContext);
  const [currentMonth, setCurrentMonth] = useState<string>(moment().format("MMMM YYYY"));
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [allProperties, setAllProperties] = useState<PropertySingleList[]>([]);

  const monthOptions = useMemo(() => generateMonthOptions(), []);
  const currentDate = moment().format("YYYY-MM-DD");

  const [monthName, year] = currentMonth.split(" ");
  const startOfMonth = moment(`${monthName} ${year}`, "MMMM YYYY")
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfMonth = moment(`${monthName} ${year}`, "MMMM YYYY")
    .endOf("month")
    .format("YYYY-MM-DD");

  const daysInMonth = useMemo(() => {
    const [monthName, year] = currentMonth.split(" ");
    const startOfMonth = moment(`${monthName} ${year}`, "MMMM YYYY");
    const daysInMonth = startOfMonth.daysInMonth();
    const firstDayOfWeek = startOfMonth.day();

    const days: Day[] = [];

    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push({ date: "", dayOfWeek: i, day: null });
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const date = startOfMonth.clone().add(i - 1, "days");
      days.push({
        date: date.format("YYYY-MM-DD"),
        dayOfWeek: date.day(),
        day: date.date(),
      });
    }

    while (days.length % 7 !== 0) {
      days.push({ date: "", dayOfWeek: days.length % 7, day: null });
    }

    return days;
  }, [currentMonth]);

  useEffect(() => {
    const fetchAndSetProperties = async () => {
      if (!userId) return;

      try {
        setLoading(true);
        const response = await fetchSingleViewProperties(userType, userId);
        const properties = response.data.properties || [];
        setAllProperties(properties);

        if (properties.length > 0 && !selectedProperty) {
          setSelectedProperty(properties[0].id);
        }
      } catch (error) {
        console.error("Error fetching properties:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndSetProperties();
  }, [userId]);

  useEffect(() => {
    if (allProperties.length > 0 && !selectedProperty) {
      setSelectedProperty(allProperties[0].id);
    }
  }, [allProperties, selectedProperty]);

  const handleMonthChange = useCallback(
    (direction: "prev" | "next") => {
      const newMonth = moment(currentMonth, "MMMM YYYY")
        .add(direction === "next" ? 1 : -1, "month")
        .format("MMMM YYYY");
      setCurrentMonth(newMonth);
    },
    [currentMonth]
  );

  const handleMonthSelectChange = useCallback((selectedMonth: string) => {
    setCurrentMonth(selectedMonth);
  }, []);

  const handlePropertySelect = useCallback((propertyId: string) => {
    setSelectedProperty(propertyId);
  }, []);

  const { data: reservationsData, isLoading: isReservationsLoading } = useQuery(
    ["reservations", selectedProperty, startOfMonth, endOfMonth],
    () => {
      if (!selectedProperty) return Promise.resolve([]);
      return fetchPropertiesReservations(selectedProperty, startOfMonth, endOfMonth);
    },
    {
      enabled: !!selectedProperty,
      staleTime: 1000 * 60 * 5,
    }
  );

  const isLoading = loading || isReservationsLoading;

  return (
    <div className="single-calendar-page">
      <div className="single-page-header">
        <div className="d-flex">
          <div className="single-page-header-img-container">
            <img src={calendarCheck} alt="page-header-icon" />
          </div>
          <div>
            <h1>Calendar</h1>
            <p style={{ fontSize: "16px", fontWeight: 400, color: "#677372", lineHeight: "24px", fontFamily: "Inter", marginLeft: "3px" }}>View all the reservations and jobs assigned to you. </p>
          </div>
        </div>
      </div>
      <div className="single-calendar-container">
        <div className="calendar-header mb-16 mt-1">
          <PropertyDropdown
            allProperties={allProperties}
            handlePropertySelect={handlePropertySelect}
            selectedProperty={selectedProperty}
            isFetchingMore={loading}
          />
          <div className="date-filter-container mr-8">
            <div className="date-filter">
              <button
                className="navi-btn"
                onClick={() => handleMonthChange("prev")}
                style={{ cursor: "pointer" }}
              >
                <img src={leftIcon} alt="Previous Month" />
              </button>
              <CustomMonthDropdown
                options={monthOptions}
                selectedOption={currentMonth}
                onSelect={handleMonthSelectChange}
              />
              <button
                className="navi-btn"
                onClick={() => handleMonthChange("next")}
                style={{ cursor: "pointer" }}
              >
                <img src={rightIcon} alt="Next Month" />
              </button>
            </div>
          </div>
        </div>
        <SingleViewCalendar
          daysInMonth={daysInMonth}
          calendarData={reservationsData}
          currentDate={currentDate}
          loading={isLoading}
          selectedProperty={selectedProperty || ''}
        />
      </div>
    </div>
  );
};

export default SingleViewCalendarApp;
