import React from "react";
import { TableVirtuoso } from "react-virtuoso";
import moment from "moment";
import SingleViewCell from "./SingleViewCell";

interface Day {
  date: string;
  dayOfWeek: number;
  day: number | null;
}

interface ReservationData {
  [key: string]: {
    [date: string]: any;
  };
}

interface SingleViewCalendarProps {
  daysInMonth: Day[];
  calendarData: ReservationData | null;
  currentDate: string;
  loading: boolean;
  selectedProperty: string;
}

const SingleViewCalendar: React.FC<SingleViewCalendarProps> = ({
  daysInMonth,
  calendarData,
  currentDate,
  loading,
  selectedProperty,
}) => {
  const displayedMonth = React.useMemo(() => {
    const firstRealDay = daysInMonth.find((d) => d.date);
    const fallback = moment();
    if (!firstRealDay) return fallback;

    return moment(firstRealDay.date, "YYYY-MM-DD");
  }, [daysInMonth]);

  const calendarPMSMap = calendarData?.[selectedProperty] || {};

  return (
    <TableVirtuoso
      style={{
        height: "88vh",
        border: "solid 1px #ECEEE5",
        borderRadius: 12,
        width: "99.5%",
      }}
      data={Array.from({ length: Math.ceil(daysInMonth.length / 7) })}
      fixedHeaderContent={() => (
        <tr>
          {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map(
            (day, index) => (
              <th
                key={index}
                style={{
                  width: 120,
                  height: 60,
                  background: "#143836",
                  fontWeight: 600,
                  color: "#F8F6EE",
                  textAlign: "center",
                }}
              >
                {day}
              </th>
            )
          )}
        </tr>
      )}
      itemContent={(index) => {
        const start = index * 7;
        const week = daysInMonth.slice(start, start + 7);

        return (
          <>
            {week.map((day, dayIndex) => {
              const isInDisplayedMonth =
                day.date && moment(day.date).isSame(displayedMonth, "month");

              return (
                <SingleViewCell
                  key={dayIndex}
                  date={day.date}
                  calendarPMSMap={calendarPMSMap}
                  loading={loading}
                  isInDisplayedMonth={!!isInDisplayedMonth}
                />
              );
            })}
          </>
        );
      }}
    />
  );
};

export default SingleViewCalendar;
